<template>
  <v-container>
    <v-row>
      <v-col
        cols="8"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <v-row class="mt-n3">
          <v-col cols="12" md="10">
            <PageHeader :title="title" />
          </v-col>
          <v-col cols="12" md="2" align="end">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="canConfig"
                  class="to-right pb-3"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  @click="goToConfigDDJJTopesCuota()"
                  ><v-icon size="30px">{{ settingsIcon }}</v-icon>
                </v-btn>
              </template>
              <span
                >Acceder a la configuración de DDJJ ingresos para topes en
                cuotas</span
              >
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card class="mx-auto my-0 mt-n3 mb-2" max-width="1200" outlined>
          <v-container class="pr-4">
            <v-row justify="end">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="in-out">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <v-col cols="3" :md="showHelp ? 4 : 3" class="py-0">
                    <v-text-field
                      v-model.trim="periodo"
                      label="Período"
                      outlined
                      clearable
                      dense
                      hint="Formato AAAAMM"
                      :rules="
                        rules.periodoYyyyMm.concat([
                          rules.maxLength(periodo, 6),
                          rules.requiredTrim(periodo)
                        ])
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="3" :md="showHelp ? 4 : 3" class="py-0">
                    <v-text-field
                      v-model.trim="cuil"
                      label="CUIL"
                      outlined
                      clearable
                      dense
                      :rules="
                        rules.positiveNumber.concat([
                          rules.maxLength(cuil, 11),
                          rules.requiredTrim(cuil)
                        ])
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" align="right" :md="showHelp ? 4 : 6">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="!isFormValid"
                      elevation="2"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            item-key="djTopeCuotaId"
            :items="itemsDjTopeCuota"
            :loading="loading"
            class="elevation-1"
            :search="search"
            :expanded.sync="expanded"
            show-expand
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" :md="showHelp ? 2 : 2" sm="2">
                    <v-btn
                      color="primary"
                      small
                      outlined
                      class="no-upper-case to-right"
                      v-if="canDelete"
                      v-model="deleteAllDjBtn"
                      @click="deleteDDJJTopesCuotas()"
                    >
                      Eliminar todos
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="2"
                    :md="showHelp ? 2 : 2"
                    sm="2"
                    align="end"
                    v-if="canImport"
                  >
                    <v-btn
                      color="primary"
                      @click="toggleImportDDJJTopesCuotas()"
                      class="to-right"
                    >
                      Importar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteDDJJTopesCuotas(item.djTopeCuotaId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar DDJJ de ingresos</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong>Fecha alta:</strong>
                {{ item.fechaAlta }}
                <strong style="padding-left:50px;">Usuario alta:</strong>
                {{ item.usuAlta }}
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-2 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="openModalImport"
      v-if="openModalImport"
      @keydown.esc="toggleImportDDJJTopesCuotas"
      max-width="65%"
      persistent
    >
      <NuevoDDJJIngresosParaTopesEnCuotas
        @toggleImportDDJJTopesCuotas="toggleImportDDJJTopesCuotas"
      ></NuevoDDJJIngresosParaTopesEnCuotas>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :isLoading="isLoadingDeleteBtn"
      :confirmButtonText="'Eliminar'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import Ayuda from "@/components/shared/Ayuda.vue";
import FiltersSelected from "@/components/shared/FiltersSelected";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";
import NuevoDDJJIngresosParaTopesEnCuotas from "@/components/modules/cuotas/devengamientos/NuevoDDJJIngresosParaTopesEnCuotas.vue";

export default {
  name: "DDJJIngresosParaTopesEnCuotas",
  components: {
    DeleteDialog,
    PageHeader,
    Ayuda,
    FiltersSelected,
    GoBackBtn,
    NuevoDDJJIngresosParaTopesEnCuotas
  },
  data: vm => ({
    rules: rules,
    title: "DDJJ de ingresos para topes en cuotas",
    isFormValid: false,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    loading: false,
    settingsIcon: enums.icons.SETTINGS,
    search: "",
    routeToGo: "ImportesADevengar",
    searchIcon: enums.icons.SEARCH,
    deleteIcon: enums.icons.DELETE,
    optionCode: enums.webSiteOptions.DDJJ_INGRESOS_TOPES_CUOTAS,
    allowedActions: null,
    canImport: false,
    canDelete: false,
    canConfig: false,
    deleteAllDjBtn: false,
    showDeleteModal: false,
    idsToDelete: [],
    titleDelete: "",
    isLoadingDeleteBtn: false,
    expanded: [],
    //filtros
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    showFilters: false,
    filtersApplied: [],
    periodo: null,
    cuil: null,
    //datatable
    loading: false,
    itemsDjTopeCuota: [],
    headers: [
      {
        text: "Código",
        sortable: false,
        value: "djTopeCuotaRNMEP"
      },
      {
        text: "Apellido y nombre",
        sortable: false,
        value: "afiliadoNom"
      },
      {
        text: "Parentesco",
        sortable: false,
        value: "afiliadoParentesco"
      },
      {
        text: "CUIL",
        sortable: false,
        value: "djTopeCuotaCuil"
      },
      {
        text: "Período",
        sortable: false,
        value: "djTopeCuotaPeriodo"
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "end",
        width: "5%"
      },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
        align: "end"
      }
    ],
    openModalImport: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    // setea el periodo actual de entrada
    this.periodo = this.periodoActual();
    this.applyFilters();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getDDJJTopeCuotas: "devengamientos/getDDJJTopeCuotas",
      deleteDDJJIngresosTopesCuotas:
        "devengamientos/deleteDDJJIngresosTopesCuotas",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .IMPORTAR_DDJJ_INGRESOS_TOPES_CUOTAS:
            this.canImport = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_DDJJ_INGRESOS_TOPES_CUOTAS:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions
            .CONFIG_DDJJ_INGRESOS_TOPES_CUOTAS:
            this.canConfig = true;
            break;
          default:
            break;
        }
      });
    },
    goToConfigDDJJTopesCuota() {
      this.$router.push({
        name: "ConfigDDJJIngresosParaTopesEnCuotas"
      });
    },
    async applyFilters() {
      this.showFilters = false;
      this.loading = true;
      this.customizeFiltersApplied();
      const filters = {
        periodo: this.periodo,
        cuil: this.cuil
      };
      try {
        const res = await this.getDDJJTopeCuotas(filters);
        this.itemsDjTopeCuota = res;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.periodo) {
        this.filtersApplied.splice(0, 1, {
          key: "periodo",
          label: "Período",
          model: this.periodo
        });
      }
      if (this.cuil) {
        this.filtersApplied.splice(1, 1, {
          key: "cuil",
          label: "CUIL",
          model: this.cuil
        });
      }
      if (!this.cuil && !this.periodo) {
        this.filtersApplied.splice(1, 2, {
          key: "",
          label: "Período y CUIL",
          model: "Todos"
        });
      }
    },
    deleteDDJJTopesCuotas(id) {
      this.idsToDelete = [];
      if (id != undefined && id != null) {
        this.titleDelete = "¿Desea eliminar el registro?";
        this.idsToDelete.push(id);
        this.showDeleteModal = true;
      } else {
        //es eliminar todos
        this.titleDelete = "¿Desea eliminar todos los registros?";
        this.idsToDelete = this.itemsDjTopeCuota.map(x => x.djTopeCuotaId);
        this.showDeleteModal = true;
      }
    },
    async confirmDelete() {
      this.isLoadingDeleteBtn = true;
      try {
        const response = await this.deleteDDJJIngresosTopesCuotas(
          this.idsToDelete
        );
        if (response.status == 200) {
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.showDeleteModal = false;
          this.isLoadingDeleteBtn = false;
        }
        this.applyFilters();
      } catch {
        this.showDeleteModal = false;
        this.isLoadingDeleteBtn = false;
      }
    },
    periodoActual() {
      let now = new Date();
      let month = ("0" + (now.getMonth() + 1)).slice(-2);
      let periodoNow = now.getFullYear() + month;

      return periodoNow;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    toggleImportDDJJTopesCuotas() {
      this.openModalImport = !this.openModalImport;
      this.applyFilters();
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    toggleFiltersSelected() {
      this.showFilters = !this.showFilters;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped>
.no-upper-case {
  text-transform: none;
}
</style>
