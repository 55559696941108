<template>
  <div>
    <v-card>
      <v-container>
        <v-card-title class="primary--text">
          <v-col cols="12" md="12"
            >Nueva importación de DDJJ de ingresos para topes en cuotas</v-col
          >
        </v-card-title>
        <v-card-text>
          <v-row cols="12" class="py-0">
            <v-col cols="7">
              <v-file-input
                ref="file"
                v-model="file"
                :disabled="loading"
                :loading="loading"
                id="fileUpload"
                type="file"
                dense
                label="Txt"
                placeholder="Seleccionar el archivo .Txt"
                accept=".txt"
                outlined
                :show-size="1000"
                @change="readTxt"
                prepend-icon="mdi-file-find-outline"
              >
                <template v-slot:selection="{ text }">
                  <v-chip
                    :color="!loading ? 'primary' : 'grey lighten-1'"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="4" align="end" class="py-0 to-right">
              <v-btn outlined @click="closeModal()" class="mr-2">
                Cerrar
              </v-btn>
              <v-btn
                color="primary"
                outlined
                :disabled="this.file == null"
                :loading="loadingImportBtn"
                @click="importDatos()"
              >
                Importar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
    <v-dialog
      v-if="modalExportarExcel"
      v-model="modalExportarExcel"
      max-width="60%"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title>
            <v-col cols="12" md="12">
              <span class="primary--text"
                >Se generará un archivo con el detalle de los errores de la
                importación</span
              >
            </v-col>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="to-right" text @click="exportExcel()">
              Continuar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import FormatoArchivoDialog from "@/components/shared/FormatoArchivoDialog.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "NuevoDDJJIngresoParaTopesEnCuotas",
  directives: { mask },
  components: { FormatoArchivoDialog },
  data: vm => ({
    loading: false,
    rules: rules,
    file: null,
    content: null,
    contentToArray: null,
    loadingImportBtn: false,
    modalExportarExcel: false,
    resultsExportExcel: []
  }),
  methods: {
    ...mapActions({
      importTxtDDJJIngresosTopesCuotas:
        "devengamientos/importTxtDDJJIngresosTopesCuotas",
      setAlert: "user/setAlert"
    }),
    readTxt() {
      if (this.file) {
        this.loading = true;
        const reader = new FileReader();
        if (this.file.name.includes(".txt")) {
          // se convierte el contenido a array y se elimina el string vacio para que no de error
          reader.onload = res => {
            this.content = res.target.result;
            this.contentToArray = this.content
              .toString()
              .replaceAll("\r", "")
              .split("\n")
              .filter(x => x != "");
          };
          reader.onerror = err => console.log(err);
          reader.readAsText(this.file);
          this.loading = false;
        } else {
          this.file = null;
          this.loading = false;
          this.setAlert({
            type: "warning",
            message: "Formato de archivo incorrecto."
          });
        }
      }
    },
    async importDatos() {
      this.loadingImportBtn = true;
      const data = [];
      this.contentToArray.forEach(x => {
        data.push({
          datos: x
        });
      });
      try {
        const res = await this.importTxtDDJJIngresosTopesCuotas(data);
        if (res.status === 200) {
          this.loadingImportBtn = false;
          if (res.data.data.length != 0) {
            this.resultsExportExcel = res.data.data;
            this.modalExportarExcel = true;
          } else {
            this.setAlert({ type: "success", message: "Importado con éxito." });
            this.closeModal();
          }
        }
      } catch (error) {
        this.closeModal();
        this.loadingImportBtn = false;
      }
    },
    exportExcel() {
      let result = [];
      this.resultsExportExcel.forEach(x =>
        result.push({
          ["Registro"]: x.registro,
          ["Error"]: x.error
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Detalle errores importación");
      this.setAlert({ type: "success", message: "Importado con éxito." });
      this.toggleModalExportarExcel();
      this.closeModal();
    },
    toggleModalExportarExcel() {
      this.modalExportarExcel = !this.modalExportarExcel;
    },
    closeModal() {
      this.$emit("toggleImportDDJJTopesCuotas");
    }
  }
};
</script>
