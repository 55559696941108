var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0 pt-0 pb-0",attrs:{"cols":"8","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('v-row',{staticClass:"mt-n3"},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('PageHeader',{attrs:{"title":_vm.title}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","align":"end"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.canConfig)?_c('v-btn',_vm._g(_vm._b({staticClass:"to-right pb-3",attrs:{"icon":""},on:{"click":function($event){return _vm.goToConfigDDJJTopesCuota()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30px"}},[_vm._v(_vm._s(_vm.settingsIcon))])],1):_vm._e()]}}])},[_c('span',[_vm._v("Acceder a la configuración de DDJJ ingresos para topes en cuotas")])])],1)],1),_c('v-card',{staticClass:"mx-auto my-0 mt-n3 mb-2",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',{staticClass:"pr-4"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"10","align":"left"},on:{"click":function($event){_vm.showFilters = true}}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplied}}):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2","align":"right","align-self":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":_vm.toggleFiltersSelected}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-expand-transition',{attrs:{"mode":"in-out"}},[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","md":_vm.showHelp ? 4 : 3}},[_c('v-text-field',{attrs:{"label":"Período","outlined":"","clearable":"","dense":"","hint":"Formato AAAAMM","rules":_vm.rules.periodoYyyyMm.concat([
                        _vm.rules.maxLength(_vm.periodo, 6),
                        _vm.rules.requiredTrim(_vm.periodo)
                      ])},model:{value:(_vm.periodo),callback:function ($$v) {_vm.periodo=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"periodo"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","md":_vm.showHelp ? 4 : 3}},[_c('v-text-field',{attrs:{"label":"CUIL","outlined":"","clearable":"","dense":"","rules":_vm.rules.positiveNumber.concat([
                        _vm.rules.maxLength(_vm.cuil, 11),
                        _vm.rules.requiredTrim(_vm.cuil)
                      ])},model:{value:(_vm.cuil),callback:function ($$v) {_vm.cuil=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"cuil"}})],1),_c('v-col',{attrs:{"cols":"6","align":"right","md":_vm.showHelp ? 4 : 6}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isFormValid,"elevation":"2","small":"","type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"item-key":"djTopeCuotaId","items":_vm.itemsDjTopeCuota,"loading":_vm.loading,"search":_vm.search,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"2","md":_vm.showHelp ? 2 : 2,"sm":"2"}},[(_vm.canDelete)?_c('v-btn',{staticClass:"no-upper-case to-right",attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.deleteDDJJTopesCuotas()}},model:{value:(_vm.deleteAllDjBtn),callback:function ($$v) {_vm.deleteAllDjBtn=$$v},expression:"deleteAllDjBtn"}},[_vm._v(" Eliminar todos ")]):_vm._e()],1),(_vm.canImport)?_c('v-col',{attrs:{"cols":"2","md":_vm.showHelp ? 2 : 2,"sm":"2","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.toggleImportDDJJTopesCuotas()}}},[_vm._v(" Importar ")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [(_vm.canDelete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteDDJJTopesCuotas(item.djTopeCuotaId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar DDJJ de ingresos")])]):_vm._e()]}},{key:"item.data-table-expand",fn:function(ref){
                      var item = ref.item;
                      var isExpanded = ref.isExpanded;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.handleExpansion(item, isExpanded)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(isExpanded ? "mdi-account-check" : "mdi-account"))])]}}],null,true)},[_c('span',[_vm._v("Usuarios")])])]}},{key:"expanded-item",fn:function(ref){
                      var headers = ref.headers;
                      var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('strong',[_vm._v("Fecha alta:")]),_vm._v(" "+_vm._s(item.fechaAlta)+" "),_c('strong',{staticStyle:{"padding-left":"50px"}},[_vm._v("Usuario alta:")]),_vm._v(" "+_vm._s(item.usuAlta)+" ")])]}}],null,true)})],1)],1),(_vm.showIcon)?_c('v-col',{staticClass:"text-right pt-2 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":_vm.toggleHelp,"toggleIcon":function($event){return _vm.toggleIcon()}}})],1):_vm._e()],1),(_vm.openModalImport)?_c('v-dialog',{attrs:{"max-width":"65%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleImportDDJJTopesCuotas($event)}},model:{value:(_vm.openModalImport),callback:function ($$v) {_vm.openModalImport=$$v},expression:"openModalImport"}},[_c('NuevoDDJJIngresosParaTopesEnCuotas',{on:{"toggleImportDDJJTopesCuotas":_vm.toggleImportDDJJTopesCuotas}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'35%',"isLoading":_vm.isLoadingDeleteBtn,"confirmButtonText":'Eliminar',"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }